import React, {useContext, useEffect} from 'react'
import Layout from '../components/layout'
import UserContext from '../context/UserContext'
import {navigate} from 'gatsby'
/*import Interests from "../components/interests";*/

const Account = (props) => {

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!userContext.user) {
      return navigate('/')
    }
  }, [userContext.user])

  const generateRow = (label, value) => {
    return <div className={"grid grid-cols-5 lg:grid-cols-3 lg:text-xl mb-2"}>
      <div className={"col-span-2 lg:col-span-1"}>{label}</div>
      <div className={"col-span-3 lg:col-span-2 font-semibold"}>{value}</div>
    </div>
  }

  return (
    <Layout>
      <main className="wrapper">
        <div className={""}>
          <img style={{objectFit: 'cover'}} src={'/images/age-meet/account-banner.png'}/>
        </div>
        <div className={"flex -mt-4 mb-8 lg:mt-8"}>
          {userContext.user && <div className={"grid lg:grid-cols-4 gap-4"}>
            <div className={'lg:col-span-1 text-center'}>
              <img className={'rounded-lg'} width={200} height={200} src={userContext.user.picture.replace('s96', 's512')}/>
            </div>
            <div className={'lg:col-span-3'}>
              <h3>Your Data</h3>
              {generateRow('Name', userContext.user.name)}
              {generateRow('City', userContext.user.city)}
              {generateRow('Phone no.', userContext.user.phone)}
              {generateRow('Email', userContext.user.email)}
            </div>
          </div>}
        </div>
      </main>
      {/*<UserContext.Consumer>
        {({user, updateState}) => (
          <main className="wrapper">
            {user && <div className={'mb-4'}>
              <div className={'flex'}>
                <div className={'flex-1'}>
                  {user.avatar && <Avatar style={{
                                        width: '200px',
                                        height: '200px',
                                        margin: 'auto'
                                    }} {...user.avatar}/>}
                  <div className={'-mt-8 text-center cursor-pointer'}
                       onClick={() => {
                         updateState('openAvatarDialog', true)
                       }}>
                    Change Avatar
                  </div>
                </div>
                <div className={'flex-1 flex-grow-2'}>
                  <div>{user.name}</div>
                  <div>{user.email}</div>
                  <div>
                    <div className={'text-xl font-semibold'}>Interests</div>
                    <div>
                                            <Interests selected={user.interests} readOnly={true}/>
                                        </div>
                  </div>
                </div>
              </div>
            </div>}
          </main>
        )}
      </UserContext.Consumer>*/}
    </Layout>
  )
}

export default Account
